
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './UserComponent/Home';
import Contact from './UserComponent/Contact';
import NavHeader from './UserComponent/NavHeader';
import Footer from './UserComponent/Footer';
import Branch from './UserComponent/Branch';
import OurCompany from './UserComponent/OurCompany';
import Product from './UserComponent/Product';


import AdminLogin from './AdminComponent/AdminLogin';
import AdminHome from './AdminComponent/AdminHome';
import FeedBack from './AdminComponent/FeedBack';
import Carousel_img from './AdminComponent/AdminMasters/Carousel_img';
import AdminNav from './AdminComponent/AdminNav';
import Category from './AdminComponent/AdminMasters/Category';
import Products from './AdminComponent/AdminMasters/Products';
import Founder from './AdminComponent/AdminMasters/Founder';
import AdminBranch from './AdminComponent/AdminMasters/AdminBranch';
import OurCompanyAdmin from './AdminComponent/AdminMasters/OurCompanyAdmin';





function App() {
  return (
    <>
    <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/navHeader" element={<NavHeader />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/branch" element={<Branch />} />
        <Route path="/ourCompanyUser" element={<OurCompany />} />
        <Route path="/userProduct" element={<Product />} />


        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/adminHome" element={<AdminHome />} />
        <Route path="/feedback" element={<FeedBack />} />
        <Route path="/carouselImg" element={<Carousel_img />} />
        <Route path="/adminNav" element={<AdminNav />} />
        <Route path="/ourCompanyAdmin" element={<OurCompanyAdmin />} />
        <Route path="/category" element={<Category />} />
        <Route path="/product" element={<Products />} />
        <Route path="/founder" element={<Founder />} />
        <Route path="/adminBranch" element={<AdminBranch />} />
        

    </Routes>
    </>
  );
}

export default App;
