import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo1 from '../Assets/logoNew.png'
import { Container } from 'react-bootstrap'
import axios from 'axios';
import { BranchMasterFinduser, catagorygetapi } from '../AllAPIURL/UserAPI';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const NavHeader = () => {


  const [Branch_master,setBranch_master]=useState([])
  const navigate=useNavigate()

  const GetBranch = (e) => {
    axios
      .get(BranchMasterFinduser)
      .then((res) => {
        if (res.data !== null) {
          setBranch_master(res.data)
          }
        
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  useEffect(() => {
    GetBranch()
   
  }, [])
  // const handalecatagory=(item)=>{
  // const id =item.id
  // navigate('/userProduct',{state:id})

  // }


  const handalebranch =(val)=>{

    const id=val
    navigate('/branch',{state:{id}})

  }
  return (
    <Container >
        <div style={{marginBottom:"81px"}}>
        <Navbar expand="lg" style={{backgroundColor:"white"}} className="shadow fixed-top py-3 ">
        <Container fluid >
          <Navbar.Brand className='ps-md-5'><img src={logo1} alt="" width={150} height={40} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0  "
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link href="/#/" className='mx-3'><b className='navItem '>HOME</b></Nav.Link>
              <NavDropdown title={<b className='navItem '>ABOUT</b>} className=' mx-3' id="navbarScrollingDropdown">
                <NavDropdown.Item href="/#/ourCompanyUser" style={{ color: "#003c63" }}><h6>COMPANY PROFILE</h6></NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/#/userProduct" className='mx-3'><b className='navItem '>PRODUCT</b></Nav.Link>
              {/* <NavDropdown title={<b className='navItem '>PRODUCTS</b>} className=' mx-3' id="navbarScrollingDropdown"> */}
              {/* {Catagory_master.map((item,index)=>{
                return( */}

                  {/* <NavDropdown.Item onClick={()=>handalecatagory(item)}  style={{ color: "rgb(124, 58, 237)" }}><i>{item.cat_name}</i></NavDropdown.Item> */}
                {/* );
                
              })} */}
             

              {/* </NavDropdown> */}

              <NavDropdown title={<b className='navItem '>BRANCHES</b>} className=' mx-3' id="navbarScrollingDropdown">
              {Branch_master.map((item,index)=>{

                return(
                  <NavDropdown.Item onClick={()=>handalebranch(item.id)} style={{ color: "#003c63",marginTop:"0px" }}><p>{item.name}{item.branch_type==="H.O" ? '(Head Office)':null}</p></NavDropdown.Item>
                );
              })}
               
              
              </NavDropdown>

              <Nav.Link href="/#/contact" className=' mx-3'><b className='navItem'>CONTACT US</b></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        </div>
    </Container>
  )
}

export default NavHeader