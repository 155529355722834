import React, { useEffect, useState } from 'react'
import AdminNav from '../AdminNav'
import { Card, Col, Form, Row, Table } from 'react-bootstrap'
import { CatagoryMasterFind, ProducatMasterDelete, ProducatMasterFind, ProducatMasterSave, ProducatMasterUpdate } from '../../AllAPIURL/AdminAPI';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

const Products = () => {

  // ============ Modal ==========
  const [show, setShow] = useState(false);
  const [img,setimg]=useState()

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setimg(item)
    
    setShow(true);
  }
//  End================


const [Catagory_master,setCatagory_master]=useState([])
const [producatname,setproducatname]=useState("")
const [cataogry ,setcataogry ]=useState()
const [Image,setImage]=useState(null)
const [selectedImageURL, setSelectedImageURL] = useState(null);
const [data,setData]=useState([])
const [isEditing,setisEditing]=useState(false)
const [id,setid]=useState()

//Save Logic

const savedata = (e) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("file", Image);
  formData.append("pro_name", producatname);
  formData.append("catid", cataogry);
  axios
    .post(ProducatMasterSave, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data !== null) {
        // alert("Data Saved");
        toast.success('Data Saved Successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // Fetch data again after save
        Getdata();
        setImage(null);
        setSelectedImageURL(null);
        setproducatname(null)
        setcataogry(null)
        window.location.reload();
      }
    })
    .catch((err) => {
      // alert("Failed to upload, try again");
      toast.error('Failed to upload, try again', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.log(err);
    });
};
//Getdata Logic
const Getdata = (e) => {
  axios
    .get(ProducatMasterFind)
    .then((res) => {
      if (res.data !== null) {
        
        setData(res.data)
        }
      setisEditing(false)
    })
    .catch((err) => {
      // alert("Failed to upload, try again");
      toast.error('Failed to upload, try again', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.log(err);
    });
};
useEffect(() => {
  if (isEditing===false) {
    Getdata()
  }
}, [isEditing])

const handleImageSelect = (e) => {
  const selectedFile = e.target.files[0];
  setImage(selectedFile);

  // Create a temporary URL for the selected image and set it in the state
  setSelectedImageURL(URL.createObjectURL(selectedFile));
};

  //Handle Edite Image 
  const setImageFile = (imageName) => {

    const relativeImagePath =`Server/Producat_image/${imageName}`
  
    axios.get(process.env.PUBLIC_URL + relativeImagePath, { responseType: 'blob' })
      .then((response) => {
        const imageBlob = response.data;
        const imageFile = new File([imageBlob], imageName);
        setImage(imageFile);
  
      })
      .catch((error) => {
        console.error('Failed to fetch image:', error);
        // Handle the error as needed
      });
  };
//handele edit
const setEditDataFun=(item)=>{
 
  const img=`Server/Producat_image/${item.image}`

  setSelectedImageURL(img)
  setproducatname(item.pro_name)
  setcataogry(item.catid)
  setisEditing(true)
  setid(item.id)
  setImageFile(item.image)
}

//Update Data 
const updatedata = () => {
let formData = new FormData();
formData.append("file", Image);
formData.append("pro_name", producatname);
formData.append("catid", cataogry);
formData.append("id", id);
axios
  .put(`${ProducatMasterUpdate}${id}`, formData, {
    headers: {  
      "Content-Type": "multipart/form-data",
    },
  })
  .then((res) => {
    if (res.data !== null) {
      // alert("File Updated Successfully");
      toast.success('Data Updated Successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // Fetch data again after update
      Getdata();
      setImage(null);
      setSelectedImageURL(null);
      setproducatname(null)
      setcataogry(null)
      window.location.reload();
    }


  })
  .catch((err) => {
    // alert("Failed to upload, try again");
    toast.error('Failed to update, try again', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    console.log(err);
  });

  
};

//delete data
const onDelete=(item)=>{



axios
  .delete(`${ProducatMasterDelete}${item.id}`,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  .then((res) => {
    if (res.data !== null) {
      // alert("File Deleted Succesfully");
      toast.success('Data Deleted Succesfuly!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });


    }
    Getdata();
  })
  .catch((err) => {
    // alert("Failed to upload, try again");
    toast.error('Failed to upload, try again', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    console.log(err);
  });
}





  //GetGetCatagorydatadata Logic
  const GetCatagorydata = (e) => {
    axios
      .get(CatagoryMasterFind)
      .then((res) => {
        if (res.data !== null) {
          setCatagory_master(res.data)
          }
        
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  useEffect(() => {
      GetCatagorydata()
  }, [])
  return (
    <container >
       <div>
      <AdminNav/>
      </div>

      <Row className="m-0 p-0 px-md-5 mx-md-5">
        <Col>
        <div className=' rounded shadow  p-3 m-md-5' style={{ backgroundColor:"white",  boxShadow:" 2px 2px 4px gray"}} >
      <Row>
      <Row className='m-0'>
      <Col className='text-center mb-3'>
        <h2 style={{color:"#003c63",fontFamily:"sans-serif "}}>PRODUCT</h2>
      </Col>
      </Row>
      <Row className='m-0'>
      <Col sm={12} md={4}>
      <Form.Label><h5 ><b>Product Name :</b></h5></Form.Label>
          <Form.Control
            aria-label="Default select example"
            onChange={(e)=>setproducatname(e.target.value)}
            value={producatname}
            required
          >
          </Form.Control>
        </Col>
        <Col sm={12} md={3}>
      <Form.Label><h5 ><b>Category :</b></h5></Form.Label>
          <Form.Select
            aria-label="Default select example"
            required
            onChange={(e)=>setcataogry(e.target.value)}
            value={cataogry}
          >
            <option value="">Choose .....</option>
            {Catagory_master.map((item,index)=>{
              return(
                <option value={item.id}>{item.cat_name}</option>
              );
            })}
            
        
          </Form.Select>
        </Col>
      </Row>
      <Form onSubmit={savedata}  encType="multipart/form-data">
      
      <Row className='m-0 mt-2'>
      <Form.Group  controlId="formBasicCheckbox">
        <Form.Label><h5 ><b>Image</b></h5></Form.Label>
        <Form.Control
          type="file"
          onChange={handleImageSelect}
          required
          name="file"
        /></Form.Group>
      </Row>

     <Row className='pt-4' >
      <Col sm={12} className='text-center' style={{display:"flex",justifyContent:"center"}}>
      <Card className='text-center' style={{ width: '13rem' , border:"0px",height:"12rem" }}>
        <Card.Img variant="top" loading="lazy"
        src={selectedImageURL}
          style={{ height: '8rem'}} />
        <Card.Body>
          <Card.Text className='text-center'>
          <b>Uploaded Image</b>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>
      </Row>
      
      <Row className='m-0 mb-2'>
        <Col style={{display:"flex", justifyContent:"center"}}>

        {
            isEditing===false ? 
             <button className='adminButton py-2 px-3 mx-2 rounded' type='submit' >Save</button>

             : 
            <button className='adminButton py-2 px-3 mx-2 rounded' type='button' onClick={updatedata} >Update</button>
         }
        </Col>
      </Row>
    </Form>
      </Row>
    </div>
        </Col>
      </Row>

      {/* ============= edit delete ======== */}

      <Row className='m-0 p-0 px-2 px-md-5 py-2 mb-5'>
        <Table striped bordered hover size="sm" className='shadow' style={{textAlign:"center"}}>
            <thead >
                <tr > 
                    <th style={{backgroundColor:"#b1d6ed",alignItems:"center"}} className='text-center'><h5><b>Sr.No</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed",alignItems:"center"}} className='text-center'><h5><b>Product Name</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed",alignItems:"center"}} className='text-center'><h5><b>Category</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed",alignItems:"center"}} className='text-center'><h5><b>View Image</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed",alignItems:"center"}} className='text-center'><h5><b>Edit</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed",alignItems:"center"}} className='text-center'><h5><b>Delete</b></h5></th>
                </tr>
            </thead>
            <tbody>
               {data.map((item,index)=>{
                return(               
                <tr >
                    <td style={{ textAlign:"center"}}>{item.id}</td>
                    <td style={{ textAlign:"center"}}>{item.pro_name}</td>
                    <td style={{ textAlign:"center"}}>{item.cat_name}</td>
                    <td style={{textAlign:"center"}}>
                    <button className='adminButtonGreen rounded my-2 px-1 py-1'
                    //  onClick={() => setEditDataFun(item)}
                    onClick={()=>handleShow(item.image)}
                    >View Photo</button>
                    </td>
                    <td style={{textAlign:"center"}}>
                    <button className=' m-1' style={{color:"orange",border:"0px", backgroundColor:"transparent"}}
                     onClick={() => setEditDataFun(item)}
                    ><i class="fa-solid fa-pen-to-square"></i></button></td>
                    <td style={{textAlign:"center"}}>
                    <button className=' m-1' style={{color:"red",border:"0px", backgroundColor:"transparent"}}
                      onClick={() => onDelete(item)}
                     ><i className="fa-solid fa-trash"></i></button></td>
                    
                </tr>
                
               )
               
                })}
            </tbody>
        </Table>
      </Row>

      <Row className='m-0 p-0  fixed-bottom py-2' style={{backgroundColor:"#003c63" ,display:"flex",justifyContent:"center",alignContent:"center"}}>
     <h6 className='text-center text-light'>2023 DESIGN & DEVELOPED BY | TechnoAarv</h6>
     </Row>

      {/* ====================Modal =================== */}
  
      <Modal show={show} onHide={handleClose}     size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body >
        <img className='w-100'
        src={`Server/Producat_image/${img}`}
        />
        </Modal.Body>
      </Modal>



      </container>
  )
}

export default Products