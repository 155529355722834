import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import NavHeader from './NavHeader'
import aboutImg from '../Assets/build.jpg';
import OurCompany1 from '../Assets/quality1.jpg'
import OurCompany2 from '../Assets/vision.avif'
import OurCompany3 from '../Assets/mission.jpg'
import founderImg from '../Assets/user.jpg'
import Footer from './Footer';
import axios from 'axios';
import { FounderMasterFind, OurCompanyMasterFind } from '../AllAPIURL/AdminAPI';
import { toast } from 'react-toastify';

const OurCompany = () => {
  const [ourcompony,setourcompony]=useState([])
  const [Founder,setFounder]=useState([])


//  const OurCompanyMasterFind='http://192.168.29.114:8090/api/OurCompony/find'//Find
//  const FounderMasterFind='http://192.168.29.114:8090/api/Founder/find'//Find
 
  
  const GetdataOurComponay = (e) => {
    axios
      .get(OurCompanyMasterFind)
      .then((res) => {
        if (res.data !== null) {
          setourcompony(res.data)
          }
  
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };

  const Getdatafounder = (e) => {
    axios
      .get(FounderMasterFind)
      .then((res) => {
        if (res.data !== null) {
        setFounder(res.data)
          }
         
  
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };

  useEffect(() => {
   
      GetdataOurComponay()
      Getdatafounder()
    
  }, [])

     //HEADING FONT ===================

     const headingStyle = {
      fontSize: '40px',
      fontWeight: 'bold',
      textAlign: 'center',
    };
  
    const subheadingStyle = {
      color: '#175d89', // Assuming this is the color for "text-violet-800"
      fontWeight: 300, // Assuming this is the font weight for "font-[300]"
    };
  return (
    <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
        <div>
      <div>
      <NavHeader/>
      </div>

      <Row className='m-0 ' >
        <Col className='text-center'>
            {/* <p style={{color:"#8B5CF6",display:"flex", justifyContent:"center"}} className='  mt-4'>
            <span className='fs-1'>Our&nbsp;</span><span  className='fw-1 fs-1 text-dark bolder'><b>Company</b></span>
            </p> */}
            <div className="heading text-black pt-2 pt-md-2 mt-2 mt-md-3 " style={headingStyle}>
           <span style={subheadingStyle}>Our</span> Company
           </div>
        </Col>
      </Row>
      {ourcompony.map((item,index)=>{

return(
  <Row className='p-1 p-md-5 m-0'>
  <Col sm={12} md={6} ><img className="w-100 rounded overflow-hidden shadow-xl" src={`Server/OurCompony_image/${item.image}`}/></Col>
  <Col sm={12} md={6} style={{display:"flex",alignItems:"center",fontFamily:"sans-serif"}} > <p ><span className='fs-2 ' style={{color:"#8B5CF6",lineHeight:"70px"}}>{item.Slogan}</span><br/>
 <span style={{fontSize:"17px",lineHeight:"25px"}}>{item.paragraph}.</span><br/><br/>
 
 </p>
  </Col>
</Row>
)
})}

      <Row className='m-0 ' >
        <Col className='text-center'>
            {/* <p style={{color:"#8B5CF6",display:"flex", justifyContent:"center"}} className='  mt-5'>
            <span className='fs-1'>Our</span><span  className='fw-1 fs-1 text-dark bolder'><b>&nbsp;Vision, Quality & Mission</b></span>
            </p> */}
            <div className="heading text-black mt-2 mb-4 " style={headingStyle}>
           <span style={subheadingStyle}>Our</span> Vision, Quality & Mission
           </div>
        </Col>
      </Row>
      <Row className='g-4 mx-2 mx-md-5 py-2 py-md-3' style={{display:"flex"}}>
        <Col sm={12} md={4}>
            <Card className='shadow' style={{border:"0px"}}>
            <Card.Img variant="top" src={OurCompany2} className='w-100' style={{height:"19rem", backgroundPosition:"center",backgroundSize:"center"}} />
            <Card.Body style={{height:"20rem"}}>
            <Card.Title><b>Vision</b></Card.Title>
            <Card.Text>
            To Extend our customers expectation in designs, quality, logistics, pre & after sales service and cost through R&D and continous improvement and customer interaction.
            </Card.Text>
            <Col className='mt-4'>
            <Button style={{backgroundColor:"rgb(229, 231, 235)",borderRadius:"15px",justifyContent:"center",color:"rgb(82, 90, 109)"}} className='border-0 px-2 py-1 mx-2'>#photography</Button>
            <Button style={{backgroundColor:"rgb(229, 231, 235)",borderRadius:"15px",justifyContent:"center",color:"rgb(82, 90, 109)"}} className='border-0 px-2 py-1 mx-2'>#travel</Button>
            <Button style={{backgroundColor:"rgb(229, 231, 235)",borderRadius:"15px",justifyContent:"center",color:"rgb(82, 90, 109)"}} className='border-0 px-2 py-1 mx-2'>#winter</Button>
            </Col>
            </Card.Body>
            </Card>
        </Col>
        <Col sm={12} md={4}>
        <Card className='shadow' style={{border:"0px"}}>
            <Card.Img variant="top" src={OurCompany1} className='w-100' style={{height:"19rem" ,backgroundPosition:"center",backgroundSize:"center"}} />
            <Card.Body style={{height:"20rem"}}>
            <Card.Title><b>Quality</b></Card.Title>
            <Card.Text>
            All Metro Group products are supplied to our numerous branches with quality standards. Metro Group provides smart and sustainable products with aesthetics and innovation. Our products are manufactured using the most advanced technologies and processes to stand the test of time.
            </Card.Text>
            <Col className='mt-4'>
            <Button style={{backgroundColor:"rgb(229, 231, 235)",borderRadius:"15px",justifyContent:"center",color:"rgb(82, 90, 109)"}} className='border-0 px-2 py-1 mx-2'>#photography</Button>
            <Button style={{backgroundColor:"rgb(229, 231, 235)",borderRadius:"15px",justifyContent:"center",color:"rgb(82, 90, 109)"}} className='border-0 px-2 py-1 mx-2'>#travel</Button>
            <Button style={{backgroundColor:"rgb(229, 231, 235)",borderRadius:"15px",justifyContent:"center",color:"rgb(82, 90, 109)"}} className='border-0 px-2 py-1 mx-2'>#winter</Button></Col>
            </Card.Body>
            </Card>
        </Col>
        <Col sm={12} md={4}>
        <Card className='shadow' style={{border:"0px"}}>
            <Card.Img variant="top" src={OurCompany3} className='w-100' style={{height:"19rem" ,backgroundPosition:"center",backgroundSize:"center"}} />
            <Card.Body style={{height:"20rem"}}>
            <Card.Title><b>Mission</b></Card.Title>
            <Card.Text>
            We are aiming to fulfill our vision of becoming the most recognized brand in bathroom solutions by providing international quality products at affordable prices to the Indian market for a better home and happier life.
            </Card.Text>
            <Col className='mt-4'>
            <Button style={{backgroundColor:"rgb(229, 231, 235)",borderRadius:"15px",justifyContent:"center",color:"rgb(82, 90, 109)"}} className='border-0 px-2 py-1 mx-2'>#photography</Button>
            <Button style={{backgroundColor:"rgb(229, 231, 235)",borderRadius:"15px",justifyContent:"center",color:"rgb(82, 90, 109)"}} className='border-0 px-2 py-1 mx-2'>#travel</Button>
            <Button style={{backgroundColor:"rgb(229, 231, 235)",borderRadius:"15px",justifyContent:"center",color:"rgb(82, 90, 109)"}} className='border-0 px-2 py-1 mx-2'>#winter</Button></Col>
            </Card.Body>
            </Card>
        </Col>
      </Row>
{ Founder.map((item,index)=>{
return(
  <Row className='m-0 pt-md-4 py-3 mt-5' style={{backgroundColor:"rgb(243, 244, 246)"}}>
        <Col sx={1} md={2} >
        
        </Col>
        <Col sx={1} md={3} >
            <h3 className='text-center pt-2' style={{color:"#003c63", marginBottom:"-20px",fontFamily: "'Poppins', sansSerif"}}>Meet Our Founder</h3>
            
                <div className='p-5' style={{marginBottom:"-20px"}}>
                <img style={{borderRadius:"50%"}} src={`Server/Founder_image/${item.image}`}className='w-100' />
                </div>
            
        </Col>
        <Col sx={1} md={5} style={{fontSize:"16px"}}>
          <p className='mt-md-5 mt-3'></p>
          <p className='mt-3'>{item.discription}</p>
          <b>{item.name}</b>
          <p>{item.designation}</p>
        </Col>
        <Col sx={1} md={2} >

        </Col>
      </Row>

)

})}
      

      <Row className='m-0'>
        <Footer/>
      </Row>
      
      </div>
    </Container>
  )
}

export default OurCompany