import React, { useEffect } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo1 from '../Assets/logoNew.png';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../AdminComponent/admin.css'

const AdminNav = () => {

    const navigate=useNavigate()

    const logoutFun = () => {
      sessionStorage.clear()
      navigate("/admin");    
    }
  
    useEffect(() => {
      let AdminData=sessionStorage.getItem("AdminData");
  
      if(AdminData === null){
        sessionStorage.clear()
        navigate("/admin")
      }
    }, [])
  return (
    <Container Fluid className='m-0 w-100'> 
        <div style={{marginBottom:"81px"}}>
        <Navbar expand="lg" style={{backgroundColor:"white"}} className="shadow fixed-top py-3 ">
      <Container fluid>
      <Navbar.Brand  className='ps-md-5'><img src={logo1} alt="" width={150} height={40}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto my-2 my-lg-0  fw-bolder"
            style={{ maxHeight: '100px',alignItems:"center" }}
            navbarScroll
          >
            <Nav.Link href="/#/adminHome"  className='mx-3'><b className='navItem '>HOME</b></Nav.Link>
            <NavDropdown title={<b className='navItem '>MASTERS</b>} className=' mx-3' id="navbarScrollingDropdown">
              <NavDropdown.Item href="/#/carouselImg"><p style={{color:"#003c63"}}>Carousel Image</p></NavDropdown.Item>
              <NavDropdown.Item href="/#/ourCompanyAdmin"><p style={{color:"#003c63"}}>Our Company</p></NavDropdown.Item>
              <NavDropdown.Item href="/#/category"><p style={{color:"#003c63"}}>Category</p></NavDropdown.Item>
              <NavDropdown.Item href="/#/product"><p style={{color:"#003c63"}}>Products</p></NavDropdown.Item>
              <NavDropdown.Item href="/#/founder"><p style={{color:"#003c63"}}>Founder</p></NavDropdown.Item>
              <NavDropdown.Item href="/#/adminBranch"><p style={{color:"#003c63"}}>Branch</p></NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/#/feedback" className=' mx-3'><b className='navItem'>FEEDBACK</b></Nav.Link>
            <Nav.Link href="" className=' mx-3 ' onClick={logoutFun}><button className='py-1 px-2 adminButton rounded' ><b >Log-Out <i class="fa-solid fa-right-from-bracket"></i></b></button></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
    </Container>
  )
}

export default AdminNav