import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../UserComponent/User.css'

const Footer = () => {
  
  return (
    <Container fluid className='m-0 p-0'  >
          {/* ========================= footer ============= */}

    <Row className='p-2  px-md-5 py-md-3 pt-md-5 m-0' style={{backgroundColor:"#003c63",display:"flex",justifyContent:"center"}}>
      
      <Col sm={12} md={5} className=' text-center pb-4  p-2 px-5' style={{color:"white",lineHeight:"26px",borderBottom:"1px solid white"}}>
      <div>
        <h4  style={{ paddingBottom:"15px",color:"white"}}><b><h5>Location</h5> Metro Group</b></h4>
        <p style={{fontSize:"18px"}}>Vishapur - Khanpur Road, Vishapur Tal. Tasgaon, Dist Sangli(MH) Pin 416 314</p>
        </div>
      </Col>
      <Col sm={12} md={2} className=' text-center  p-2 pb-4' style={{lineHeight:"30px",color:"white",borderBottom:"1px solid white"}}>
      <div>
        <h5  style={{ paddingBottom:"15px"}}>Quick Links</h5>
        </div>
          <div >
          <Link to="/"
                style={{textDecoration:"none", color:"white" }} >
               
                  <b className='big'>Home</b>
          </Link><br/>
          <Link to="/ourCompanyUser"
                style={{textDecoration:"none", color:"white"}} >
              
                <b className='big'>About</b>
          </Link><br/>
          <Link to="/userProduct"
                style={{textDecoration:"none", color:"white"}} >
               
                <b className='big'>Products</b>
          </Link><br/>

          <Link to="/contact"
                style={{textDecoration:"none", color:"white"}} >
                
                <b className='big'>Contact</b>
          </Link><br/>
          </div>
      </Col>
      <Col sm={12} md={5} className=' text-center p-2 pb-4' style={{color:"white",lineHeight:"29px",borderBottom:"1px solid white"}}>
          <div className='text-start text-center '>
          <h5  style={{ paddingBottom:"15px"}}>Contact</h5>
          </div>
          <div className='text-start text-center'>
          <a href="tel:9765022996" style={{ textDecoration: "none", color: "white", color: "white" }}>
            
            <b>Phone :</b> +91 9765022996 / 7588088356
          </a><br/>
          <a href="mailto:marketing@metrogroupsmg.com" style={{textDecoration: "none", color: "white", color: "white" }}>
             
            <b>Email :</b> <span>marketing@metrogroupsmg.com</span>
            </a><br/>
            <a href="linkto:www.metrogroupsmg.com" style={{textDecoration: "none", color: "white", color: "white" }}>
            
            <b>Web :</b><span>www.metrogroupsmg.com</span>
            </a><br/>
          </div>

      </Col>
    {/* </Col> */}
  </Row>
  <Row className='m-0 p-0 pb-3' style={{backgroundColor:"#003c63" ,display:"flex",justifyContent:"center"}}>
   <h6 className='text-center text-light'>2023 DESIGN & DEVELOPED BY | TechnoAarv Solution</h6>
</Row>
    </Container>
  )
}

export default Footer