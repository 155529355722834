import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import NavHeader from './NavHeader'
import '../UserComponent/User.css'
import Footer from './Footer'
import { saveapi } from '../AllAPIURL/UserAPI'
import axios from 'axios'
import { BranchMasterFind } from '../AllAPIURL/AdminAPI'
import { toast } from 'react-toastify'

const Contact = () => {

  const [name1, setName1] = useState()
  const [mobileNum,setMobileNum] = useState()
  const [subject,setSubject] = useState()
  const [message,setMessage] = useState()
  const [email,setEmail] = useState()
  const [branch,setbranch]=useState([])




  const Getdatabranch = (e) => {
    axios
      .get(BranchMasterFind)
      .then((res) => {
        if (res.data !== null) {
          const data=res.data
          const headobje=data.find((item)=>item.branch_type === "H.O")
        
          if(headobje){
        
            setbranch(headobje)       
          }
          else{
           
            setbranch(headobje) 
          }
        // setbranch(res.data)
          }
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };

  useEffect(() => {

      Getdatabranch()
    
  }, [])
  const handleSubmit = (event) => {

    setName1("")
    setMobileNum("")
    setSubject("")
    setMessage("")
    setEmail("")

    event.preventDefault();
    const saveObj = {

      name:name1,
      mobile_no:mobileNum,
      subject:subject,
      message:message,
      email_id:email

    }
    axios
      .post(saveapi, saveObj)
      .then((res) => {
            
            if (res.data !== null) {
              toast.success(' Successfully!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
        })
        .catch((err) => console.log(err));
     }

     //HEADING FONT ===================

     const headingStyle = {
      fontSize: '40px',
      fontWeight: 'bold',
      textAlign: 'center',
    };
  
    const subheadingStyle = {
      color: '#175d89', // Assuming this is the color for "text-violet-800"
      fontWeight: 300, // Assuming this is the font weight for "font-[300]"
    };
     

  return (
    <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
        <div>
      <div>
      <NavHeader/>
      </div>

      <Row className='m-0 ' >
        <Col className='text-center mt-md-4 mt-2'>
            {/* <p style={{color:"#8B5CF6",display:"flex", justifyContent:"center"}} className='  mt-4'>
            <span className='fs-1'>Get In&nbsp;</span><span  className='fw-1 fs-1 text-dark bolder'><b>Touch</b></span>
            </p> */}
            <div className="heading text-black pt-3 pt-2 pt-md-3 mt-2 mt-md-1 mb-2" style={headingStyle}>
           <span style={subheadingStyle}>Get In</span> Touch
           </div>
        </Col>
      </Row>

      <Row className='m-0 mt-3' >
        <Col>
          <h5 style={{color:"#003c63",display:"flex", justifyContent:"center"}}><b>CONTACT INFO</b></h5>
        </Col>
      </Row>

      <Row className='m-0 px-md-5 px-3 g-4' >
        <Col sx={1} md={4} >
          <Card className='px-4 py-3 shadow rounded border-0' style={{backgroundColor:"#003c63",color:"white",height:"9rem"}}>
          <h5 className='mb-3'>Address:</h5>
          <p>{branch.city}.</p>
          </Card>
        </Col>
        <Col sx={1} md={4} >
          <Card className='px-4 py-3 shadow rounded border-0' style={{backgroundColor:"#003c63",color:"white",height:"9rem"}}>
          <h5 className='mb-3'>Phone :</h5>
          <p><i style={{ paddingRight: "10px",color:"white" }} className="fa-solid fa-phone text-light"></i> {branch.mobile_no}</p>
          </Card>
        </Col>
        <Col sx={1} md={4} >
          <Card className='px-4 py-3 shadow rounded border-0' style={{backgroundColor:"#003c63",color:"white",height:"9rem"}}>
          <h5 className='mb-3'>Email :</h5>
          <a href="mailto:marketing@wellvitbath.com" style={{textDecoration: "none", color: "white", color: "white" }}>
              <i style={{ paddingRight: "10px", color:"white"}} className="fa-solid fa-envelope text-light"></i>   
               <span>{branch.email_id}</span>
              </a>
          </Card>
        </Col>
      </Row>

      <Row className='m-0' >
        <Col className='mt-5'>
          <h5 style={{color:"#003c63",display:"flex", justifyContent:"center"}}><b>SEND US MESSAGE</b></h5>
        </Col>
      </Row>

      <Row className='m-0 mx-3'>
        <Col sm={1} md={3}></Col>
        <Col sm={10} md={6} className='border-0 mt-4 py-3 shadow rounded '>
        <Form onSubmit={handleSubmit}>
          <Row className='m-0 mb-3'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label><b>NAME :</b></Form.Label>
              <Form.Control   placeholder="Enter Name" 
                required
                value={name1}
                onChange={(e) => setName1(e.target.value)}
              />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label><b>EMAIL :</b></Form.Label>
              <Form.Control type="email" placeholder="Enter Email" 
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              </Form.Group>
            </Col>
          </Row>
          <Row className='m-0 mb-3'>
          <Col sm={12} md={6}>
            <Form.Group className="mb-3" controlId="formBasicNum">
            <Form.Label><b>PHONE :</b></Form.Label>
            <Form.Control  placeholder="Enter Number"
              required
              value={mobileNum}
              onChange={(e) => setMobileNum(e.target.value)}
              pattern="[0-9]{10}"
              maxlength="10"
              title='Please enter 10 digit num.'
              type="tel"
              id="mobileNumber"
             />
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group className="mb-3" controlId="formBasicSubject">
            <Form.Label><b>SUBJECT :</b></Form.Label>
            <Form.Control type="text" placeholder="Enter Subject" 
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            </Form.Group>
          </Col>
          </Row>
          <Row className='m-0 mb-3'>
            <Col>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label><b>MESSAGE :</b></Form.Label>
              <Form.Control as="textarea" rows={4} 
                required
                value={message}
                 onChange={(e) => setMessage(e.target.value)}
              />
              </Form.Group>
            </Col>
          </Row>
          <Row className='m-0 mb-3'>
            <Col style={{display:"flex",justifyContent:"center"}}>
            <button type='submit' className='py-2 px-3 rounded button-2 shadow' style={{ fontSize:"15px"}} >SEND MESSAGE</button>
            </Col>
          </Row>
       </Form>
        </Col>
        <Col sm={1} md={3}></Col>
      </Row>

      <Row className='m-0 mt-5 '>
        <Col className='m-0 p-0 mt-md-3' >
        <iframe src={branch.location} height="450"  style={{border:"none", width:"100%",marginBottom:"-7px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Col>
      </Row>

      <Row className='m-0'>
        <Footer/>
      </Row>





      </div>
      </Container>
  )
}

export default Contact