import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import NavHeader from './NavHeader'
import '../UserComponent/User.css'
import collection1 from '../Assets/onepiece_1.png';
import axios from 'axios';
import Footer from './Footer';
import { catagorygetapi, producatgetapi, profucatapifindbyid } from '../AllAPIURL/UserAPI';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const Product = () => {
    const [Catagory_master,setCatagory_master]=useState([])
    const [Producat_Master,setProducat_Master]=useState([])
    // const location=useLocation()
    // const catid=location.state.id
    // const catagorygetapi="http://192.168.29.114:8090/api/Catagoray/find"
    // const producatgetapi="http://192.168.29.114:8090/api/Producat/find"

const handlecart = (item) => {
  if(item === undefined){
  

    axios
      .get(`${producatgetapi}`)
      .then((res) => {
        if (res.data !== null) {
          setProducat_Master(res.data)
          }
        
      })
      .catch((err) => {
      
      });
  }

    else{
  const id=item.id
  axios
    .get(`${profucatapifindbyid}${id}`)
    .then((res) => {
      if (res.data !== null) {
        setProducat_Master(res.data)
        }
      
    })
    .catch((err) => {
    
    });

}
}


const GetCatagorydata = (e) => {
    axios
      .get(catagorygetapi)
      .then((res) => {
        if (res.data !== null) {
          setCatagory_master(res.data)
          }
        
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  useEffect(() => {
      GetCatagorydata()
      handlecart()
  }, [])

   //HEADING FONT ===================

   const headingStyle = {
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
  };

  const subheadingStyle = {
    color: '#003c63', // Assuming this is the color for "text-violet-800"
    fontWeight: 300, // Assuming this is the font weight for "font-[300]"
  };

  return (
    <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
    <div>
  <div>
  <NavHeader/>
  </div>

  <Row className='m-0 ' >
    <Col className='text-center mt-md-4 mt-2'>
        {/* <p style={{color:"#8B5CF6",display:"flex", justifyContent:"center"}} className='  mt-4'>
        <span className='fs-1'>Our&nbsp;</span><span  className='fw-1 fs-1 text-dark bolder'><b>Collections</b></span>
        </p>  */}
        <div className="heading text-black mt-4 mb-2" style={headingStyle}>
           <span style={subheadingStyle}>Our</span> Collections
           </div>
    </Col>
  </Row>

  <Row className='m-0 px-md-5 px-3 mt-md-4 mt-2 mb-5' style={{display:"flex"}}>
    <Col sm={12} md={4} className='mb-4'>
    <Card className='rounded border-0 px-2' style={{backgroundColor:"#bfdef2",}}>
        <h5 className='text-center pt-3 ' style={{color:"#175d89",borderBottom:"1px solid"}}>Our Collections</h5>
        <a className='products' onClick={()=>handlecart(undefined)} type='button'><span className='productItem' ><i class="fas fa-arrow-circle-right"></i> All</span></a>
{Catagory_master.map((item,index)=>{
    return(
        <a className='products' onClick={()=>handlecart(item)} type='button'><span className='productItem'> <i class="fas fa-arrow-circle-right"></i> {item.cat_name}</span></a>
    );

})}
       
        </Card>
    </Col>
    <Col sm={12} md={8}>
    <Row className='g-4'  style={{display:"flex"}}>
    {Producat_Master.map((item,index)=>{

        return(
            <Col sm={12} md={4} ><Card className='shadow' style={{backgroundColor:"#003c63"}}>
      <div className='m-3 '><Card.Img variant="top" height={280}  className='w-100  rounded border p-4 bg-light' src={`Server/Producat_image/${item.image}`} /></div>
        <Card.Title className='text-center py-1 text-light' style={{marginTop:"-10px"}}>{item.pro_name}</Card.Title>
    </Card> 
    </Col>
        );
    })}
    
    
   
    </Row>
    </Col>
  </Row>
  
  <Row className='m-0'>
        <Footer/>
  </Row>



  </div>
  </Container>
  )
}

export default Product