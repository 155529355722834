import { BaseIP } from "./BaseIp";

// Crouslae Master Api
export const CrousaleMasterSave=BaseIP+'api/Crousal/uploade'//Save
export const CrousaleMasterUpdate=BaseIP+'api/Crousal/update'//Update
export const CrousaleMasterFind=BaseIP+'api/Crousal/find'//Find
export const CrousaleMasterDelete=BaseIP+'api/Crousal/delete'//Delete


// OurCompony Master Api
export const OurCompanyMasterSave=BaseIP+'api/OurCompony/add'//Save
export const OurCompanyMasterUpdate=BaseIP+'api/OurCompony/update'//Update
export const OurCompanyMasterFind=BaseIP+'api/OurCompony/find'//Find
export const OurCompanyMasterDelete=BaseIP+'api/OurCompony/delete'//Delete

// Catagory Master Api
export const CatagoryMasterSave=BaseIP+'api/Catagoray/add'//Save
export const CatagoryMasterUpdate=BaseIP+'api/Catagoray/update'//Update
export const CatagoryMasterFind=BaseIP+'api/Catagoray/find'//Find
export const CatagoryMasterDelete=BaseIP+'api/Catagoray/delete'//Delete


// Producat Master Api
export const ProducatMasterSave=BaseIP+'api/Producat/add'//Save
export const ProducatMasterUpdate=BaseIP+'api/Producat/update'//Update
export const ProducatMasterFind=BaseIP+'api/Producat/find'//Find
export const ProducatMasterFindbyid=BaseIP+'api/Producat/findbyid'//Find by cat id
export const ProducatMasterDelete=BaseIP+'api/Producat/delete'//Delete


// Founder Master Api
export const FounderMasterSave=BaseIP+'api/Founder/add'//Save
export const FounderMasterUpdate=BaseIP+'api/Founder/update'//Update
export const FounderMasterFind=BaseIP+'api/Founder/find'//Find
export const FounderMasterDelete=BaseIP+'api/Founder/delete'//Delete

// Branch Master Api
export const BranchMasterSave=BaseIP+'api/Branch/add'//Save
export const BranchMasterUpdate=BaseIP+'api/Branch/update'//Update
export const BranchMasterFind=BaseIP+'api/Branch/find'//Find
export const BranchMasterDelete=BaseIP+'api/Branch/delete'//Delete

// Feedback Master Api
export const FeedbackMasterFind=BaseIP+'api/message/find'//Find
export const FeedbackMasterDelete=BaseIP+'api/message/delete'//Delete


// Admin Master Api
export const adminlogin=BaseIP+'api/admin/login'//Save
