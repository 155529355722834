import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row } from 'react-bootstrap';
import AdminNav from './AdminNav';

const AdminHome = () => {

   
  return (
    <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
      <div>
      <AdminNav/>
      </div>
      <div className='text-center py-5'> <h1>HOME </h1></div>

    <Row className='m-0 p-0 fixed-bottom  py-2' style={{backgroundColor:"#003c63" ,display:"flex",justifyContent:"center",alignContent:"center"}}>
     <h6 className='text-center text-light'>2023 DESIGN & DEVELOPED BY | TechnoAarv</h6>
  </Row>
 </Container>
  )
}

export default AdminHome
