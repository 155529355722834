import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo1 from '../Assets/logoNew.png'
import '../AdminComponent/admin.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { adminlogin } from '../AllAPIURL/AdminAPI';
import { toast } from 'react-toastify';


const AdminLogin = () => {
  const [emailid,setemailid]=useState()
  const [password,setpassword]=useState()
  const navigate=useNavigate()
  const login = async (e) => {
    e.preventDefault();
  
    try {
      const admindata = {
        email_id: emailid,
        password: password,
      };
  
      const response = await axios.post(adminlogin, admindata);
  
      if (response.data === "NOUTFOUND") {
        // alert("CHECK YOUR CREDENTIALS");
        toast.success('CHECK YOUR CREDENTIALS!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        const data = JSON.stringify(response.data);
        sessionStorage.setItem("AdminData", data);
        navigate('/adminHome');
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed, e.g., display an error message to the user.
    }
  };
  
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  
  return (
    <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
    <div className='pt-4 ' style={{display:"flex",justifyContent:"center"}}>
        <div ><img src={logo1} alt="" width={200} height={60}/>
    </div>
    </div>
     

  {/* ====================== login design ================= */}

  <Row className='m-0 my-2 py-5 align-item-center '>
    <Col  style={{display:"flex",justifyContent:"center"}}>
        <Col sm={1} md={4}></Col>
        <Col sm={10} md={4} className=' shadow rounded' style={{backgroundColor:"#003c63"}}>
         <div className='login-container'>     
         <div className='login-form'>
            <form>
                <div className='input'>
                    {/* <div style={{display:"flex",justifyContent:"center"}}>
                       <img  src={beforelogo}/>
                    </div> */}
                    
                    <h1 className='text-center'><i class="fa-solid fa-user fs-2" ></i> Login</h1>
                    <input id='input' type="email" placeholder='Email Id' onChange={(e)=>setemailid(e.target.value)} required/>

                    <input id='input1' type="password" placeholder='Password' onChange={(e)=>setpassword(e.target.value)} required/>

                    <div className='login-button'>
                    <button type="submit"  className='login-btn' onClick={login} >LOGIN</button>
                    </div>
                </div>
            </form> 
          </div>
        </div>
        </Col>
        <Col sm={1} md={4}></Col>
    </Col>
  </Row>
  <Row className='m-0 p-0 fixed-bottom  py-2' style={{backgroundColor:"#003c63" ,display:"flex",justifyContent:"center"}}>
     <h6 className='text-center text-light'>2023 DESIGN & DEVELOPED BY | TechnoAarv</h6>
  </Row>
  </Container>
  )
}

export default AdminLogin;