import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import NavHeader from "./NavHeader";
import HODimg from "../Assets/company.jpg";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import axios from "axios";
import { BranchMasterFind } from "../AllAPIURL/AdminAPI";
import "../UserComponent/User.css";
import { toast } from "react-toastify";

const Branch = () => {
  const location = useLocation();
const id = location.state?.id;

const [branch, setBranch] = useState([]);
const [catid, setCatid] = useState(1);

const Getdatabranch = (id) => {
  axios
    .get(BranchMasterFind)
    .then((res) => {
      if (res.data !== null) {
        const data = res.data;
        const branchobj = data.find((item) => item.id === id); // Use === for comparison
        // const filteredData = data.filter((item) => item !== headobje);
        // let newarray=[]
        // newarray.push(headobje)
       
        // if (id === 2) {
        //   setBranch(filteredData);
        // } else {
        //   setBranch(newarray);
        // }
        if(branchobj){
          setBranch(branchobj)
        }

      }
    })
    .catch((err) => {
      // alert("Failed to upload, try again");
      toast.error('Failed to upload, try again', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.log(err);
    });
};




useEffect(() => {
  setCatid(id);
  if (catid !== null) {
    Getdatabranch(catid);
  }
}, [id, catid]);

// Log branch after it has been updated
  return (
    <Container
      fluid
      className="p-0 m-0"
      style={{ boxSizing: "border-box"}}
    >
      <div>
        <div>
          <NavHeader />
        </div>

            <Row className="m-0 ">
          <Col className="mt-5">
            <h3
              style={{
                color: "#003c63",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <b>{branch.name}</b>
            </h3>
          </Col>
        </Row>

        <Row className="p-1 p-md-5 m-0">
          <Col sm={12} md={6}>
            <img
              className="w-100 rounded overflow-hidden shadow-xl"
              src={`Server/Branch_image/${branch.image}`}
            />
          </Col>
          <Col
            sm={12}
            md={6}
            style={{
              display: "flex",
              alignItems: "center",
              fontFamily: "sans-serif",
            }}
          >
            {" "}
            <p>
              <span style={{ fontSize: "17px", lineHeight: "25px" }}>
                {branch.discriptation}
              </span>
              <br />
              <br />
            </p>
          </Col>
        </Row>

        <Row className="m-0 px-md-5 px-3 g-4">
          <Col sx={1} md={4}>
            <Card
              className="px-4 py-3 shadow rounded border-0"
              style={{
                backgroundColor: "#003c63",
                color: "white",
                height: "9rem",
              }}
            >
              <h5 className="mb-3">City:</h5>
              <p>{branch.city}</p>
            </Card>
          </Col>
          <Col sx={1} md={4}>
            <Card
              className="px-4 py-3 shadow rounded border-0"
              style={{
                backgroundColor: "#003c63",
                color: "white",
                height: "9rem",
              }}
            >
              <h5 className="mb-3">Phone :</h5>
              <p>
                <i
                  style={{ paddingRight: "10px", color: "white" }}
                  className="fa-solid fa-phone text-light"
                ></i>{" "}
                {branch.mobile_no}
              </p>
            </Card>
          </Col>
          <Col sx={1} md={4}>
            <Card
              className="px-4 py-3 shadow rounded border-0"
              style={{
                backgroundColor: "#003c63",
                color: "white",
                height: "9rem",
              }}
            >
              <h5 className="mb-3">Email :</h5>
              <a
                href="mailto:marketing@wellvitbath.com"
                style={{
                  textDecoration: "none",
                  color: "white",
                  color: "white",
                }}
              >
                <i
                  style={{ paddingRight: "10px", color: "white" }}
                  className="fa-solid fa-envelope text-light"
                ></i>
                <span>{branch.email_id}</span>
              </a>
            </Card>
          </Col>
        </Row>


        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3813.063800872664!2d74.61825511086803!3d17.1183869104306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc1412d573290a7%3A0xf763ac3fa0d4360a!2sShree%20ganesh%20trading%20company%20visapur!5e0!3m2!1sen!2sin!4v1711951495369!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        
        <Row className="m-0 mt-5 ">
          <Col className="m-0 p-0 mt-md-3">
            <iframe
              src={branch.location}
              width="100%"
              height="400"
              style={{ border: "none", width: "100%", marginBottom: "-7px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>


        <Row className="m-0">
          <Footer />
        </Row>
      </div>
    </Container>
  );
};

export default Branch;
