import React, { useEffect, useState } from 'react'
import AdminNav from '../AdminNav'
import { Col, Form, Row, Table } from 'react-bootstrap'
import axios from 'axios'
import { CatagoryMasterDelete, CatagoryMasterFind, CatagoryMasterSave, CatagoryMasterUpdate } from '../../AllAPIURL/AdminAPI'
import { toast } from 'react-toastify'


const Category = () => {

  const [Catagoray ,setCatagoray ]=useState()
  const [data,setData]=useState([])
  const [isEditing,setisEditing]=useState(false)
  const [id,setid]=useState()



//Save Logic

  const savedata = (e) => {
    e.preventDefault();
   const formData ={
    cat_name:Catagoray
   }
   
    axios
      .post(CatagoryMasterSave, formData)
      .then((res) => {
        if (res.data !== null) {
          // alert("Data Saved");
          toast.success('Data Saved Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          // Fetch data again after save
          Getdata();
          setCatagoray(null)
           window.location.reload();
        }
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
//Getdata Logic
  const Getdata = (e) => {
    axios
      .get(CatagoryMasterFind)
      .then((res) => {
        if (res.data !== null) {
          setData(res.data)
          }
        setisEditing(false)
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  useEffect(() => {
    if (isEditing===false) {
      Getdata()
    }
  }, [isEditing])

//handele edit
  const setEditDataFun=(item)=>{
   
    setCatagoray(item.cat_name)
    setisEditing(true)
    setid(item.id)
  }

  //Update Data 
const updatedata = () => {
  const formData ={
    id:id,
    cat_name:Catagoray
   }
  axios
    .put(CatagoryMasterUpdate, formData)
    .then((res) => {
      if (res.data !== null) {
        // alert("File Updated Successfully");
        toast.success('Data Updated Successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // Fetch data again after update
        Getdata();

       setCatagoray(null)
        window.location.reload();
       
      }


    })
    .catch((err) => {
      // alert("Failed to upload, try again");
      toast.error('Failed to update, try again', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.log(err);
    });

    
};

//delete data
const onDelete=(item)=>{



  axios
    .delete(`${CatagoryMasterDelete}${item.id}`,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data !== null) {
        // alert("File Deleted Succesfully");
        toast.success('Data Deleted Successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });


      }
      Getdata();
    })
    .catch((err) => {
      // alert("Failed to upload, try again");
      toast.error('Failed to upload, try again', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.log(err);
    });
}


  return (
    <container >
    <div>
   <AdminNav/>
   </div>

   <Row className="m-0 p-0 px-md-5 mx-md-5">
     <Col>
     <div className=' rounded shadow  p-3 m-md-5' style={{ backgroundColor:"white",  boxShadow:" 2px 2px 4px gray"}} >
   <Row>
   <Row className='m-0'>
      <Col className='text-center'>
        <h2 style={{color:"#003c63",fontFamily:"sans-serif"}}>CATEGORY</h2>
      </Col>
      </Row>
   <Row className='m-0'>
   <Col sm={12} md={4}>
   <Form.Label><h5 ><b>Category Name :</b></h5></Form.Label>
       <Form.Control
         aria-label="Default select example"
         onChange={(e)=>setCatagoray(e.target.value)}
         value={Catagoray}
         required
       >
       </Form.Control>
     </Col>
   </Row>
   <Form  onSubmit={savedata}  encType="multipart/form-data">
   
   <Row className='m-0 my-2'>
     <Col style={{display:"flex", justifyContent:"center"}}>

     {
            isEditing===false ? 
             <button className='adminButton py-2 px-3 mx-2 rounded' type='submit' >Save</button>

             : 
            <button className='adminButton py-2 px-3 mx-2 rounded' type='button' onClick={updatedata} >Update</button>
         }
     </Col>
   </Row>
 </Form>
   </Row>
 </div>
     </Col>
   </Row>

   {/* ============= edit delete ======== */}

   <Row className='m-0 p-0 px-2 px-md-5 py-2 mb-5'>
     <Table striped bordered hover size="sm" className='shadow' style={{textAlign:"center"}}>
         <thead >
             <tr > 
                 <th style={{alignItems:"center", backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Sr.No</b></h5></th>
                 <th style={{alignItems:"center", backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Category Name</b></h5></th>
                 <th style={{alignItems:"center", backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Edit</b></h5></th>
                 <th style={{alignItems:"center", backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Delete</b></h5></th>
             </tr>
         </thead>
         <tbody>
           {data.map((item,index)=>{
             return(               
             <tr className='border' >
                 <td style={{ textAlign:"center"}}>{item.id}</td>
                 <td style={{ textAlign:"center"}}>{item.cat_name}</td>
                 <td style={{textAlign:"center"}}>
                <button className=' m-1' style={{color:"orange",border:"0px", backgroundColor:"transparent"}}
                  onClick={() => setEditDataFun(item)}
                ><i class="fa-solid fa-pen-to-square"></i></button></td>
                <td style={{textAlign:"center"}}>
                <button className=' m-1' style={{color:"red",border:"0px", backgroundColor:"transparent"}}
                 onClick={() => onDelete(item)}
                 ><i className="fa-solid fa-trash"></i></button></td>
             </tr>
              )
             })} 
         </tbody>
     </Table>
   </Row>

   <Row className='m-0 p-0 fixed-bottom   py-2' style={{backgroundColor:"#003c63" ,display:"flex",justifyContent:"center",alignContent:"center"}}>
  <h6 className='text-center text-light'>2023 DESIGN & DEVELOPED BY | TechnoAarv</h6>
  </Row>

   </container>
  )
}

export default Category