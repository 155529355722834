import React, { useEffect, useState } from 'react'
import '../UserComponent/User.css'
import Container from 'react-bootstrap/Container';
import { Card, Col, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import CarouselImg1 from '../Assets/ban1.jpeg';
import CarouselImg2 from '../Assets/ban2.jpeg';
import CarouselImg3 from '../Assets/ban3.jpeg';
import aboutImg from '../Assets/build.jpg';
import collection1 from '../Assets/onepiece_1.png';
import collection2 from '../Assets/wallhung_2.png';
import collection3 from '../Assets/twopiece_3.png';
import collection4 from '../Assets/tabletop_4.png';
import collection5 from '../Assets/onepiece_1.png';
import collection6 from '../Assets/wallhung_2.png';
import collection7 from '../Assets/twopiece_3.png';
import collection8 from '../Assets/tabletop_4.png';
import product1 from '../Assets/durability.png';
import product2 from '../Assets/bect.png';
import product3 from '../Assets/wide.png';
import product4 from '../Assets/easytoinstall.png';
import product5 from '../Assets/Aesthetic.png';
import product6 from '../Assets/twin.png';
import product7 from '../Assets/chemical.png';
import product8 from '../Assets/water.png';
import mapImg from '../Assets/Metro market Map.jpg';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import NavHeader from './NavHeader';
import Footer from './Footer';
import axios from 'axios';
import { OurCompanyMasterFind, ProducatMasterFind } from '../AllAPIURL/AdminAPI';
import { crousaleMasterFind, producatgetapi } from '../AllAPIURL/UserAPI';
import { toast } from 'react-toastify';

const Home = () => {
  const [ourcompony,setourcompony]=useState([])
  const [producat,setproducat]=useState([])
  
  const [crousaleiamge1,setcrousaleiamge1]=useState(null)
  const [crousaleiamge2,setcrousaleiamge2]=useState(null)
  const [crousaleiamge3,setcrousaleiamge3]=useState(null)


  const Navigate=useNavigate()
  const readMoreFun = (()=>{
    Navigate('/ourCompanyUser')
  })
//  const OurCompanyMasterFind='http://192.168.29.114:8090/api/OurCompony/find'//Find
//  const crousaleMasterFind='http://192.168.29.114:8090/api/Crousal/find'//Find
//  const ProducatMasterFind='http://192.168.29.114:8090/api/Producat/find'//Find

  
  const GetdataOurcompony = (e) => {
    axios
      .get(OurCompanyMasterFind)
      .then((res) => {
        if (res.data !== null) {
          setourcompony(res.data)
          }
  
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  const Getcrousaledata = (e) => {
    axios
      .get(crousaleMasterFind)
      .then((res) => {
        if (res.data !== null) {
          
          const data=res.data
          const img1=data.find((item)=>item.image_name === "First")
          const img2=data.find((item)=>item.image_name === "Second")
          const img3=data.find((item)=>item.image_name === "Third")
          setcrousaleiamge1(img1.image)
          setcrousaleiamge2(img2.image)
          setcrousaleiamge3(img3.image)
          
          }
  
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  const Getdataproducat = (e) => {
    axios
      .get(producatgetapi)
      .then((res) => {
        if (res.data !== null) {
          const data=res.data
        
            //  const obj1=data.find((item)=>item.catid==1)
            if(data.length >= 8){
              const slicedData = data.slice(0, 8);
              setproducat(slicedData)
            }
            else if(data.length <8){
              setproducat(data)
            }
          // const obj2=data.find((item)=>item.catid ===2)
          // const obj3=data.find((item)=>item.catid ===3)
          // const obj4=data.find((item)=>item.catid ===4)
          // const obj5=data.find((item)=>item.catid ===5)
          // const obj6=data.find((item)=>item.catid ===6)
          // const obj7=data.find((item)=>item.catid ===7)
          // const obj8=data.find((item)=>item.catid ===8)
          // let newarray=[]

          // newarray.push(obj5,obj2,obj3,obj4)
           
          // setproducat(newarray)
          }
  
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  useEffect(() => {
   
      GetdataOurcompony()
      Getcrousaledata()
      Getdataproducat()
    
  }, [])
  
  const productFun = (() =>{
    Navigate("/userProduct")
  })

  //HEADING FONT ===================

    const headingStyle = {
      fontSize: '40px',
      fontWeight: 'bold',
      textAlign: 'center',
    };
  
    const subheadingStyle = {
      color: '#175d89', // Assuming this is the color for "text-violet-800"
      fontWeight: 300, // Assuming this is the font weight for "font-[300]"
    };

  return (
    <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
        <div>
      <div>
      <NavHeader/>
      </div>

    {/* =================== Carousel ============== */}

      <Carousel className=' m-0 p-0'>
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100 "
            src={`Server/crousale_image/${crousaleiamge1}`}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <img
            className="d-block w-100 "
            src={`Server/crousale_image/${crousaleiamge2}`}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 "
            src={`Server/crousale_image/${crousaleiamge3}`}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>

    {/* ========================= about ===================== */}

    <Row className='m-0 ' >
        <Col className='text-center'>
            {/* <p style={{color:"#8B5CF6",display:"flex", justifyContent:"center",fontFamily: 'Raleway, sans-serif',fontSize:"40px"}} className='pt-2 pt-md-3 mt-2 mt-md-5 '>
            <p className=''>Our&nbsp;</p><span  className=' text-dark '><b>Company</b></span>
            </p> */}
           <div className="heading text-black pt-2 pt-md-3 mt-2 mt-md-5 " style={headingStyle}>
           <span style={subheadingStyle}>Our</span> Company
           </div>
        </Col>
    </Row>
    {ourcompony.map((item,index)=>{

      return(
        <Row className='p-1 p-md-5 m-0'>
        <Col sm={12} md={6} ><img className="w-100 rounded overflow-hidden shadow-xl"  src={`Server/OurCompony_image/${item.image}`}/></Col>
        <Col sm={12} md={6} style={{display:"flex",alignItems:"center",fontFamily:"sans-serif"}} > <p ><span className='fs-2 ' style={{color:"#8B5CF6",lineHeight:"70px"}}>{item.Slogan}</span><br/>
       <span style={{fontSize:"17px",lineHeight:"25px"}}>{item.paragraph}.</span><br/><br/>
       <span>
          <button className='py-2 px-3 rounded button-2' onClick={readMoreFun} style={{ fontSize:"15px",}} >READ MORE</button>
       </span>
       </p>
        </Col>
    </Row>
      )
    })}
    

    {/* ======================= Our Products========================= */}

    <Row className='m-0 '>
        <Col className='text-center'>
            {/* <p style={{color:"#8B5CF6",display:"flex", justifyContent:"center"}} className='pt-3 pt-md-3 mt-2 mt-md-2 '>
            <span className='fs-1'>Our&nbsp;</span><span  className='fw-1 fs-1 text-dark bolder'><b>Collections</b></span>
            </p> */}
            <div className="heading text-black pt-3 pt-md-3 mt-2 mt-md-2 " style={headingStyle}>
           <span style={subheadingStyle}>Our</span> Collections
           </div>
        </Col>
    </Row>
    <Row className='g-4 m-0 p-1 px-md-5'>
    {producat.map((item,index)=>{

      return(
        <Col sm={12} md={3} ><Card className='shadow' style={{backgroundColor:"#003c63"}}>
      <div className='m-3 '><Card.Img variant="top" height={280}  className='w-100  rounded border p-4 bg-light' src={`Server/Producat_image/${item.image}`} /></div>
        <Card.Title className='text-center py-1 text-light' style={{marginTop:"-10px"}}>{item.pro_name}</Card.Title>
    </Card> </Col>

      );
    })}
     
      </Row>
      <Row className='m-0 mt-4'>
    <Col style={{display:"flex",justifyContent:"center"}}>
    <button className='py-2 my-3 px-3 rounded' style={{backgroundColor:"#003c63",border:"none",color:"white", fontSize:"15px"}} onClick={productFun}>VIEW MORE</button>
    </Col>
    </Row>

    {/* =================== Speciality of Products ============= */}

    <Row className='m-0'>
        <Col className='text-center'>
            {/* <p style={{color:"#8B5CF6",display:"flex", justifyContent:"center",fontFamily:"sans-serif"}} className='pt-2 pt-md-3 mt-2 mt-md-4 '>
            <span className='fs-1'>Speciality&nbsp;</span><span  className='fw-1 fs-1 text-dark bolder'><b>of Products</b></span>
            </p> */}
            <div className="heading text-black pt-3 pt-2 pt-md-3 mt-2 mt-md-4" style={headingStyle}>
           <span style={subheadingStyle}>Speciality</span> of Products
           </div>
        </Col>
    </Row>
    <Row className='g-4 m-0 p-1 px-md-5 ' style={{display:"flex",justifyContent:"center"}}>
      <Col sm={6} md={3} >
      <Card className='shadow rounded p-4 specialityOfProduct'>
        <Col  style={{display:"flex",justifyContent:"center"}}><img width={90} height={90} src={product1} /></Col>
        <Col  className='pt-3' style={{display:"flex",justifyContent:"center"}}><b>High Durability</b></Col>
      </Card>
      </Col>
      <Col sm={6} md={3} >
      <Card className='shadow rounded p-4 specialityOfProduct'>
        <Col style={{display:"flex",justifyContent:"center"}}><img width={90} height={90} src={product2} /></Col>
        <Col  className='pt-3' style={{display:"flex",justifyContent:"center"}}><b>Anti Bacterial</b></Col>
      </Card>
      </Col>
      <Col sm={6} md={3} >
      <Card className='shadow rounded p-4 specialityOfProduct'>
        <Col  style={{display:"flex",justifyContent:"center"}}><img width={90} height={90} src={product3} /></Col>
        <Col  className='pt-3' style={{display:"flex",justifyContent:"center"}}><b>Wide Range</b></Col>
      </Card>
      </Col>
      <Col sm={6} md={3} >
      <Card className='shadow rounded p-4 specialityOfProduct'>
        <Col  style={{display:"flex",justifyContent:"center"}}><img width={90} height={90} src={product4} /></Col>
        <Col  className='pt-3' style={{display:"flex",justifyContent:"center"}}><b>Easy to Install</b></Col>
      </Card>
      </Col>
      <Col sm={6} md={3} >
      <Card className='shadow rounded p-4 specialityOfProduct'>
        <Col  style={{display:"flex",justifyContent:"center"}}><img width={90} height={90} src={product5} /></Col>
        <Col  className='pt-3' style={{display:"flex",justifyContent:"center"}}><b>Rimless Design</b></Col>
      </Card>
      </Col>
      <Col sm={6} md={3} >
      <Card className='shadow rounded p-4 specialityOfProduct'>
        <Col sm={12} md={12} style={{display:"flex",justifyContent:"center"}}><img width={90} height={90} src={product6} /></Col>
        <Col sm={12} md={12} className='pt-3' style={{display:"flex",justifyContent:"center"}}><b>Twin Flushing Technology</b></Col>
      </Card>
      </Col>
      <Col sm={6} md={3} >
      <Card className='shadow rounded p-4 specialityOfProduct'>
        <Col  style={{display:"flex",justifyContent:"center"}}><img width={90} height={90} src={product7} /></Col>
        <Col  className='pt-3' style={{display:"flex",justifyContent:"center"}}><b>Chemical and Stain Resistance</b></Col>
      </Card>
      </Col>
      <Col sm={6} md={3} >
      <Card className='shadow rounded p-4 specialityOfProduct'>
        <Col sm={12} md={12} style={{display:"flex",justifyContent:"center"}}><img width={90} height={90} src={product8} /></Col>
        <Col sm={12} md={12} className='pt-3' style={{display:"flex",justifyContent:"center"}}><b>Saving Water</b></Col>
      </Card>
      </Col>
    </Row>

    {/* ===================== Our Global Market =========================== */}
      
    <Row className='m-0'>
        <Col className='text-center'>
            {/* <p style={{color:"#8B5CF6",display:"flex", justifyContent:"center",fontFamily:"sans-serif"}} className='pt-2 pt-md-4 mt-2 mt-md-5 '>
            <span className='fs-1'>Our&nbsp;</span><span  className='fw-1 fs-1 text-dark bolder'><b>Global Market</b></span>
            </p> */}
            <div className="heading text-black pt-2 pt-md-4 mt-2 mb-3  mt-md-5" style={headingStyle}>
           <span style={subheadingStyle}>Our</span> Indian Market
           </div>
        </Col>
    </Row>
    <Row className='m-0 p-1  px-md-5'>
      <Col className='text-center' style={{display:"flex",justifyContent:"center",fontSize:"17px",fontFamily: 'Poppins, sans-serif'}}>
        <p>Continuously working to build entrepreneurs through our group and expanding our reach across different regions of India. Focusing on Maharashtra, Karnataka, Goa and other regions as strategic growth markets, Metro Group is keen to enter new geographies to grow the business. Supplying products in different cities through Metro Group and Metro Group is able to supply products in Indian market.</p>
      </Col>
    </Row>
    <Row className='m-0'>
      <Col className='px-2 px-md-5'>
        <img style={{marginTop:"-20px"}} className='w-100' src={mapImg} />
      </Col>
    </Row>
    <Row className='m-0 pb-5'>
      <Col sm={1} md={2}></Col>
      <Col sm={10} md={8} style={{ display:"flex", justifyContent:"center",fontFamily: 'Raleway, sans-serif',color:"#175d89"}} className='text-center'><h3>"You Can Consult The Complete SanitaryWares Collection Catalogue From All Your Devices".</h3></Col>
      <Col sm={1} md={2}></Col>
    </Row>

  <Row className='m-0'>
      <Footer/>
  </Row>

    </Container>
  )
}

export default Home

