import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import AdminNav from './AdminNav'
import axios from 'axios'
import { FeedbackMasterDelete, FeedbackMasterFind } from '../AllAPIURL/AdminAPI'
import { toast } from 'react-toastify'


const FeedBack = () => {
const [data,setData]=useState([])


  const Getdata = (e) => {
    axios
      .get(FeedbackMasterFind)
      .then((res) => {
        if (res.data !== null) {
          setData(res.data)
          }
       
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error("Failed to upload, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  useEffect(() => {
   
      Getdata()
   
  }, [])


  const onDelete = (item) => {
    axios
      .delete(`${FeedbackMasterDelete}${item.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data !== null) {
          // alert("File Deleted Succesfully");
          toast.success("Data Deleted Succesfuly!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        Getdata();
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error("Failed to upload, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  return (
    <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
      <div>
      <AdminNav/>
      </div>
      <Row className='m-0 '>
      <Col className='text-center mt-5'>
        <h2 style={{color:"#003c63", fontFamily:"sans-serif"}}>FEEDBACK</h2>
      </Col>
      </Row>

      {/* ============= edit delete ======== */}

      <Row className='m-0 p-0 px-2 mt-4 px-md-5 py-2 mb-5'>
        <Table striped bordered hover size="sm" className='shadow rounded' style={{textAlign:"center"}}>
            <thead >
                <tr > 
                    <th style={{alignItems:"center",backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Sr.No</b></h5></th>
                    <th style={{alignItems:"center", backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Name</b></h5></th>
                    <th style={{alignItems:"center", backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Email</b></h5></th>
                    <th style={{alignItems:"center", backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Phone</b></h5></th>
                    <th style={{alignItems:"center", backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Subject</b></h5></th>
                    <th style={{alignItems:"center", backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Message</b></h5></th>
                    <th style={{alignItems:"center", backgroundColor:"#b1d6ed"}} className='text-center'><h5><b>Delete</b></h5></th>
                </tr>
            </thead>
            <tbody>
              {data.map((item,index)=>{
                return(              
                <tr className='border' >
                    <td style={{ textAlign:"center"}}>{item.id}</td>
                    <td style={{ textAlign:"center"}}>{item.name}</td>
                    <td style={{ textAlign:"center"}}>{item.email_id}</td>
                    <td style={{ textAlign:"center"}}>{item.mobile_no}</td>
                    <td style={{ textAlign:"center"}}>{item.subject}</td>
                    <td style={{ textAlign:"center"}}>{item.message}</td>
                    <td style={{textAlign:"center"}}>
                    <button className=' rounded m-1' style={{color:"red",border:"0px", backgroundColor:"transparent"}}
                    onClick={() => onDelete(item)}
                     ><i className="fa-solid fa-trash"></i> </button></td>
                </tr>
                  )
                })}
            </tbody>
        </Table>
      </Row>

    <Row className='m-0 p-0 fixed-bottom  py-2' style={{backgroundColor:"#003c63" ,display:"flex",justifyContent:"center",alignContent:"center"}}>
     <h6 className='text-center text-light'>2023 DESIGN & DEVELOPED BY | TechnoAarv</h6>
  </Row>
 </Container>
  )
}

export default FeedBack