import { BaseIP } from "./BaseIp";


export const OurCompanyMasterFind=BaseIP+'api/OurCompony/find'//Find OurCompony
export const crousaleMasterFind=BaseIP+'api/Crousal/find'//Find Crousal



export const FounderMasterFind=BaseIP+'api/Founder/find'//Find Founder
export const catagorygetapi=BaseIP+"api/Catagoray/find"//Find Catagory
export const producatgetapi=BaseIP+"api/Producat/find"//Find Producat
export const saveapi=BaseIP+"api/message/add"//Contact save 
export const BranchMasterFinduser=BaseIP+'api/Branch/find'//Find
export const profucatapifindbyid=BaseIP+'api/Producat/findbyid'//Dind Producuct by Cat id 


