import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import AdminNav from '../AdminNav'
import { BranchMasterDelete, BranchMasterFind, BranchMasterSave, BranchMasterUpdate } from '../../AllAPIURL/AdminAPI';
import axios from 'axios';
import { toast } from 'react-toastify';

const AdminBranch = () => {
  // ============ Modal ==========
  const [show, setShow] = useState(false);
  const [img,setimg]=useState()

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setimg(item)
    
    setShow(true);
  }
  const [name, setName] = useState("");
  const [branchtype, setbranchtype] = useState("");
  const [discription, setDiscription] = useState("");
  const [mobileno, setmobileno] = useState("");
  const [emailid, setemailid] = useState("");
  const [city, setcity] = useState("");
  const [location, setlocation] = useState("");
  const [Image, setImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [data, setData] = useState([]);
  const [isEditing, setisEditing] = useState(false);
  const [id, setid] = useState();

  //Save Logic

  const savedata = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", Image);
    formData.append("name", name);
    formData.append("branch_type", branchtype);
    formData.append("discriptation", discription);
    formData.append("mobile_no", mobileno);
    formData.append("location", location);
    formData.append("city", city);
    formData.append("email_id", emailid);
    axios
      .post(BranchMasterSave, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data !== null) {
          // alert("Data Saved");
          toast.success("Data Saved Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          // Fetch data again after save
          Getdata();
          setImage(null);
          setSelectedImageURL(null);
          setemailid(null);
          setcity(null);
          setlocation(null);
          setbranchtype(null);
          setDiscription(null);
          setmobileno(null);
          setName(null);
          window.location.reload();
        }
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error("Failed to upload, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  //Getdata Logic
  const Getdata = (e) => {
    axios
      .get(BranchMasterFind)
      .then((res) => {
        if (res.data !== null) {
          setData(res.data);
        }
        setisEditing(false);
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error("Failed to upload, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };
  useEffect(() => {
    if (isEditing === false) {
      Getdata();
    }
  }, [isEditing]);

  const handleImageSelect = (e) => {
    const selectedFile = e.target.files[0];
    setImage(selectedFile);

    // Create a temporary URL for the selected image and set it in the state
    setSelectedImageURL(URL.createObjectURL(selectedFile));
  };

//update image for edit 
const setImageFile = (imageName) => {

  const relativeImagePath =`Server/Branch_image/${imageName}`

  axios.get(process.env.PUBLIC_URL + relativeImagePath, { responseType: 'blob' })
    .then((response) => {
      const imageBlob = response.data;
      const imageFile = new File([imageBlob], imageName);
      setImage(imageFile);

    })
    .catch((error) => {
      console.error('Failed to fetch image:', error);
      // Handle the error as needed
    });
};
  //handele edit
  const setEditDataFun = (item) => {
    const img=`Server/Branch_image/${item.image}`
    setSelectedImageURL(img);
    setemailid(item.email_id);
    setcity(item.city);
    setlocation(item.location);
    setbranchtype(item.branch_type);
    setDiscription(item.discriptation);
    setmobileno(item.mobile_no);
    setName(item.name);
    setid(item.id);
    setisEditing(true);
    setid(item.id);
    setImageFile(item.image)
  };

  //Update Data
  const updatedata = () => {
    let formData = new FormData();
    formData.append("file", Image);
    formData.append("name", name);
    formData.append("branch_type", branchtype);
    formData.append("discriptation", discription);
    formData.append("mobile_no", mobileno);
    formData.append("location", location);
    formData.append("city", city);
    formData.append("email_id", emailid);
    formData.append("id", id);
    axios
      .put(`${BranchMasterUpdate}${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data !== null) {
          // alert("File Updated Successfully");
          toast.success("File Updated Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          // Fetch data again after update
          Getdata();
          setImage(null);
          setSelectedImageURL(null);
          setemailid(null);
          setcity(null);
          setlocation(null);
          setbranchtype(null);
          setDiscription(null);
          setmobileno(null);
          setName(null);
          window.location.reload();
        }
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error("Failed to update, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };

  //delete data
  const onDelete = (item) => {
    axios
      .delete(`${BranchMasterDelete}${item.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data !== null) {
          // alert("File Deleted Succesfully");
          toast.success("File Deleted Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        Getdata();
      })
      .catch((err) => {
        // alert("Failed to upload, try again");
        toast.error("Failed to delete, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };



  return (
    <container >
       <div>
      <AdminNav/>
      
      </div>

      <Row className="m-0 p-0 px-md-5 mx-md-5">
        <Col>
        <div className=' rounded shadow  p-3 m-md-5' style={{ backgroundColor:"white",  boxShadow:" 2px 2px 4px gray"}} >
      <Row>
      <Row className='m-0'>
      <Col className='text-center mb-2'>
        <h2 style={{color:"#003c63",fontFamily:"sans-serif"}}>BRANCH</h2>
      </Col>
      </Row>
      <Row className='m-0'>
      <Col sm={12} md={6} className='mt-2'>
      <Form.Label><h5 ><b>Branch Name :</b></h5></Form.Label>
          <Form.Control
            aria-label="Default select example"
            onChange={(e)=>setName(e.target.value)}
            value={name}
            required
          >
          </Form.Control>
        </Col>

        <Col sm={12} md={6} className='mt-2'>
        <Form.Label><h5 ><b>Branch Type :</b></h5></Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e)=>setbranchtype(e.target.value)}
            value={branchtype}
            required
          >
            <option value="">Choose .....</option>
            <option value="H.O">Head Office</option>
            <option value="S.O">Sub Office</option>
          </Form.Select>
        </Col>
        
        <Col sm={12} md={4} className='mt-2'>
        <Form.Label><h5 ><b>Mobile No :</b></h5></Form.Label>
          <Form.Control
            aria-label="Default select example"
            pattern="[0-9]{10}"
                        maxlength="10"
                        title='Please enter 10 digit num.'
                        type="tel"
            onChange={(e)=>setmobileno(e.target.value)}
            value={mobileno}
            required
          >
          </Form.Control>
        </Col>

        <Col sm={12} md={4} className='mt-2'>
        <Form.Label><h5 ><b>Email Id :</b></h5></Form.Label>
          <Form.Control
            aria-label="Default select example"
            onChange={(e)=>setemailid(e.target.value)}
            value={emailid}
            required
          >
          </Form.Control>
        </Col>

        <Col sm={12} md={4} className='mt-2'>
        <Form.Label><h5 ><b>City :</b></h5></Form.Label>
          <Form.Control
            aria-label="Default select example"
            onChange={(e)=>setcity(e.target.value)}
            value={city}
            required
          >
          </Form.Control>
        </Col>
      </Row>
      <Form onSubmit={savedata}  encType="multipart/form-data">
      
      <Row className='m-0' >
      <Col sm={12} md={6} className='mt-2'>
        <Form.Label><h5 ><b>Location :</b></h5></Form.Label>
        <Form.Control
        as="textarea"
        aria-label="Default select example"
        onChange={(e)=>setlocation(e.target.value)}
            value={location}
        required
        >
        </Form.Control>
      </Col>

      <Col sm={12} md={6} className='mt-2'>
        <Form.Label><h5 ><b>Description :</b></h5></Form.Label>
          <Form.Control
          as="textarea"
            aria-label="Default select example"
            onChange={(e)=>setDiscription(e.target.value)}
            value={discription}
            required
          >
          </Form.Control>
        </Col>

      <Col sm={12} md={12} className='mt-2'> 
      <Form.Group  controlId="formBasicCheckbox">
        <Form.Label><h5 ><b>Image</b></h5></Form.Label>
        <Form.Control
          type="file"
          onChange={handleImageSelect}
          required
          name="file"
        /></Form.Group></Col>
      </Row>

     <Row className='pt-4' >
      <Col sm={12} className='text-center' style={{display:"flex",justifyContent:"center"}}>
      <Card className='text-center' style={{ width: '13rem' , border:"0px",height:"12rem" }}>
        <Card.Img variant="top" loading="lazy"
         src={selectedImageURL}
          style={{ height: '8rem'}} />
        <Card.Body>
          <Card.Text className='text-center'>
          <b>Uploaded Image</b>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>
      </Row>
      
      <Row className='m-0 mb-2'>
        <Col style={{display:"flex", justifyContent:"center"}}>

        {
            isEditing===false ? 
             <button className='adminButton py-2 px-3 mx-2 rounded' type='submit' >Save</button>

             : 
            <button className='adminButton py-2 px-3 mx-2 rounded' type='button' onClick={updatedata} >Update</button>
         }
        </Col>
      </Row>
    </Form>
      </Row>
    </div>
        </Col>
      </Row>

      {/* ============= edit delete ======== */}

      <Row className='m-0 p-0 px-2 px-md-5 py-2 mb-5 w-100' style={{overflow:"scroll"}}>
        <Table striped bordered hover size="sm" className='shadow w-100' style={{textAlign:"center" }}>
            <thead >
                <tr > 
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-3'><b>Sr.No</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-3'><b>Branch Name</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-3'><b>Branch Type</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-3 w-100'><b>Description</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-3'><b>Mobile No</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-3'><b>Email Id</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-3'><b>City</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-3 w-100'><b>Location</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-4'><b>View Image</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-3'><b>Edit</b></h5></th>
                    <th style={{backgroundColor:"#b1d6ed", alignItems:"center"}} className='text-center'><h5 className='mx-3'><b>Delete</b></h5></th>
                </tr>
            </thead>
            <tbody>
             { data.map((item,index)=>{
                return(             
                <tr className='border' >
                    <td style={{ textAlign:"center"}}>{item.id}</td>
                    <td style={{ textAlign:"center"}}>{item.name}</td>
                    <td style={{ textAlign:"center"}}>{item.branch_type}</td>
                    <td style={{ textAlign:"center"}} className='w-100'>{item.discriptation}</td>
                    <td style={{ textAlign:"center"}} >{item.mobile_no}</td>
                    <td style={{ textAlign:"center"}}>{item.email_id}</td>
                    <td style={{ textAlign:"center"}}>{item.city}</td>
                    <td style={{ textAlign:"center"}} className='w-100'>{item.location}</td>
                    <td style={{textAlign:"center"}}>
                    <button className='adminButtonGreen rounded my-2 px-1 py-1'
                     
                    onClick={()=>handleShow(item.image)}
                    >View Photo</button>
                    </td>
                    <td style={{textAlign:"center"}}>
                    <button className=' m-1' style={{color:"orange",border:"0px", backgroundColor:"transparent"}}
                     onClick={() => setEditDataFun(item)}
                    ><i class="fa-solid fa-pen-to-square"></i></button></td>
                    <td style={{textAlign:"center"}}>
                    <button className=' m-1' style={{color:"red",border:"0px", backgroundColor:"transparent"}}
                      onClick={() => onDelete(item)}
                     ><i className="fa-solid fa-trash"></i></button></td>
                </tr>
             )
                })} 
            </tbody>
        </Table>
      </Row>

      <Row className='m-0 p-0 fixed-bottom  py-2' style={{backgroundColor:"#003c63" ,display:"flex",justifyContent:"center",alignContent:"center"}}>
     <h6 className='text-center text-light'>2023 DESIGN & DEVELOPED BY | TechnoAarv</h6>
     </Row>
      {/* ====================Modal =================== */}
  
      <Modal show={show} onHide={handleClose}     size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body >
        <img className='w-100'
        src={`Server/Branch_image/${img}`}
     

        />
        </Modal.Body>
      </Modal>
      </container>
  )
}

export default AdminBranch
